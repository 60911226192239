.h1 {
  // font-family: "CustomFont" !important;
  text-align: center;
  font-size: 32px;
  color: #4b3e3d;
}
.mainContainer {
  // padding: 0rem 1rem;
  @media (max-width: 768px) {
    // paddingleft: 1rem;
    padding-right: 0rem;
  }
}
.details {
  border: 1px solid #b1b1b1;
  border-radius: 20px;
  max-width: 874px;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 10px 20px !important;
  @media (max-width: 888px) {
    padding: 0px !important;
    width: 95%;
    margin: auto;
    border: none;
  }
}

.avatarContainer {
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.41px;
  }
}

.avatarIcon {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.colorContainer {
  display: flex;
  align-items: center;
  gap: 10px;

  .colors {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin: 10px 0px;
    cursor: pointer;
  }
}

/* .textField {
  padding: 0px !important;
} */
.h6 {
  font-size: 13px;
  margin: 0;
  margin-top: 10px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.addressContainer {
  // margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  // margin-left: 20px;
  // margin-right: 20px;

  .colors {
    max-width: 48px;
    width: 100%;
    height: 48px;
    border-radius: 50%;
    // margin: 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      position: absolute;
      max-width: 24px;
      min-width: 24px;
      width: 100%;
      height: 17px;
    }
  }
}

.btnContainer {
  // margin: 20px;
  // margin-top: 5px;
  // display: flex;
  // width: 100%;
  justify-content: center;
  // flex-direction: column;
  .btn {
    background-color: transparent;
    color: #000000;
    border: none;
    outline: none;
    border-radius: 32px;
    height: 48px;
    width: 100%;
    cursor: pointer;
    font-weight: 600;
  }

  .btnYellow {
    background-color: #f39200;
    color: white;
    border: none;
    outline: none;
    border-radius: 32px;
    height: 48px;
    width: 100%;
    cursor: pointer;
  }
}

.input_box {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.manually {
  margin-left: 20px !important;
  @media (max-width: 768px) {
    margin-left: 0px;
  }
}

.addressColor {
  margin-top: 18px !important;
  @media (max-width: 900px) {
    margin-top: -10px !important;
  }
}
