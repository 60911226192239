@import "../../theme/KidzCubicleTheme/Colors.scss";

.Login__back-to-btn {
  color: $black !important;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.875rem !important;
  text-align: center;
  margin-top: 1.5rem !important;
}

.Login__link-to-btn {
  cursor: pointer;
  font-size: 0.875rem !important;
  text-align: center;
  margin-top: 0.5rem;
  text-transform: none !important;
}

.Login--form-container.MuiGrid-container {
  display: flex;
  align-items: center;
}

.Login__form.MuiGrid-item {
  margin-top: 2rem;
}

.Login__forgot-password {
  width: auto;
}

.Login__buttons.MuiGrid-item {
  margin-top: 2rem;
}
.Login__Divider {
  padding: 1rem;
  margin: 0 4rem;
  color: $greyColor1;
  font-size: 0.825rem;
}
.Login__google-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-weight: 600;
}
