.Footer--container {
  border-top: 1px solid #b1b1b1;
  padding: 0.5rem;
  font-size: 0.875rem;
  color: #4b3e3d;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    // Adjust the breakpoint as needed
    padding: 0.25rem;
    font-size: 0.7rem;
  }
}
