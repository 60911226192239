@font-face {
  font-family: "SF Pro Display";
  src: url("./fonts/SF\ Pro\ Display\ Medium.woff") format("woff");
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "SF Pro Display" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  color: #4b3e3d !important;
  font-size: 15px;
  height: 49px;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

Typograpghy {
  font-family: "SF Pro Display" !important;
}

// To manage toggle eye UI.
.pr-48{
  padding-right: 48px !important;
}

.contained-btn {
  width: 100%;
  border-radius: 50px;
  padding: 10px 20px;
  font-weight: 600;
}

.MuiOutlinedInput-root {
  padding: 0px !important;
  padding-left: 10px !important;
}
.MuiSlider-rail {
  height: 8px !important;
}

.MuiSlider-track {
  color: #50a7da !important;
  height: 8px !important;
  box-shadow: 0px 5px 50px 1px rgb(118, 117, 117);
}
.MuiSlider-thumb {
  color: #ffffff !important;
  box-shadow: 0px 5px 10px 1px rgb(182, 182, 182);
}

.css-dgznzn-MuiFormLabel-root-MuiInputLabel-root {
  font-family: "SF Pro Display" !important;
}

.css-x0ojdv-MuiTypography-root {
  font-family: "SF Pro Display" !important;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-top: 5px !important;
  @media (max-width: 768px) {
  }
}
.css-u68mfq-MuiFormLabel-root-MuiInputLabel-root {
  overflow: visible !important;
}

.toast {
  .Toastify__progress-bar--success {
    background-color: #f39200;
  }

  svg {
    fill: #f39200;
  }
}
.cursor-pointer{
  cursor: pointer;
}

.manage_Tabs{

  @media  (max-width:"450px"){
    font-size: 16px !important;
  }
  @media  (max-width:"400px"){
    font-size: 13px !important;
  }
  @media  (max-width:"390px"){
    font-size: 12.6px !important;
  }
  @media  (max-width:"350px"){
    font-size: 10px !important;
  }

}