@import "../../theme/KidzCubicleTheme/Colors.scss";

.VerifyOtp__back-to-btn.MuiTypography-body1 {
  color: $black;
  cursor: pointer;
  text-transform: capitalize;
  margin-top: 1.5rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.VerifyOtp--form-container.MuiGrid-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.VerifyOtp__form.MuiGrid-item {
  margin-top: 2rem;
}

.VerifyOtp__buttons.MuiGrid-item {
  margin-top: 2rem;
}

.VerifyOtp__description {
  color: $black;
  opacity: 0.6;
}
.VerifyOtp__otp {
  display: flex;
  justify-content: center;
  margin: 0 10rem;
}
.VerifyOtp__timer {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  color: $lightBlue;
}
