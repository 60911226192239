.dashed_box {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // margin: 10px;
  gap: 6px;

  // border: 1px dashed #A5A5A5;

  background-image: repeating-linear-gradient(
      0deg,
      #bababa,
      #bababa 14px,
      transparent 14px,
      transparent 20px,
      #bababa 20px
    ),
    repeating-linear-gradient(
      90deg,
      #bababa,
      #bababa 14px,
      transparent 14px,
      transparent 20px,
      #bababa 20px
    ),
    repeating-linear-gradient(
      180deg,
      #bababa,
      #bababa 14px,
      transparent 14px,
      transparent 20px,
      #bababa 20px
    ),
    repeating-linear-gradient(
      270deg,
      #bababa,
      #bababa 14px,
      transparent 14px,
      transparent 20px,
      #bababa 20px
    );
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.add_btn {
  width: 200px !important;
  border-radius: 50px !important;
  font-weight: 600;
}

.btn_div {
  height: 55px;
  width: 55px;
  background-color: #ffa20033;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}
