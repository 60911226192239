/* For screens up to 600px wide */
@media only screen and (max-width: 600px) {
  .MuiDateCalendar-root {
    width: 341px !important;
  }
}

/* For screens between 600px and 1200px wide */
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .MuiDateCalendar-root {
    width: 399px !important;
  }
}

/* For screens larger than 1200px */
@media only screen and (min-width: 1200px) {
  .MuiDateCalendar-root {
    width: 495px !important;
  }
}

.MuiDateCalendar-root {
  border: 1px solid #f39200;
  border-radius: 7px;




}


.css-1t0788u-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition,.css-1xuxf2l,.MuiDateCalendar-viewTransitionContainer{
overflow-x:visible !important;
 
padding: 10px;
}
.MuiPickersCalendarHeader-root {
  padding-left: 0px !important;
}

.MuiPickersDay-root {
  border: 1px solid #d5d4df !important;
  border-radius: 0 !important;
  background-color: #fff;
}

.css-md10om-MuiButtonBase-root-MuiPickersDay-root,
.css-1inhok,
.MuiPickersDay-dayOutsideMonth {
  background-color: #f2f3f7 !important;
}
/*header styling */
.MuiPickersCalendarHeader-label{
    color:#4B3E3D !important;
    font-weight: 900 !important;
    font-size: 1.4rem;
    margin-right: 0 !important;
    margin-left:14px !important;
}

/* header arrow icon remover*/
.MuiPickersCalendarHeader-switchViewButton {
  display: none !important;
}

// week color
.css-g8y162-MuiTypography-root-MuiDayCalendar-weekDayLabel,
.MuiDayCalendar-weekDayLabel {
  color: #000 !important;
  font-weight: 600 !important;
  font: Inter;
}
.MuiDayCalendar-header,
.css-i5q14k-MuiDayCalendar-header {
  justify-content: space-between !important;
}
.css-1aqny2q-MuiPickersCalendarHeader-root {
  padding-left: 0px !important;
}

// remove button margins
// .MuiDayCalendar-weekContainer:last-of-type{
//   background-color: red;
//   margin-bottom: 40px !important;
//   }
   .css-flbe84-MuiDayCalendar-weekContainer,.css-1lakcfi,.css-mvmu1r  {
      margin: 0 !important;
    }
 
.MuiButtonBase-root{
  margin: 0 !important;
  padding: 20px;
}
.css-muj3n1-MuiPickersDay-root {
  margin: 0 !important;
}
.css-hwq80r-MuiButtonBase-root-MuiPickersDay-root,
.css-18mws18-MuiButtonBase-root-MuiPickersDay-root,
.css-muj3n1-MuiPickersDay-root,
.css-md10om-MuiButtonBase-root-MuiPickersDay-root,
.css-1nem20r,
.css-1inhokv,
.css-rt5l2j {
  width: 64px !important;
  height: 58px !important;
  font-size: 0.85rem !important;
  color: #000 !important;
}

.Mui-disabled{  
color: gray !important;
}

.css-hwq80r-MuiButtonBase-root-MuiPickersDay-root:focus.Mui-selected,
.css-1nem20r.Mui-selected:hover,
.css-1nem20r:focus.Mui-selected {
    background-color: #f39200 !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

::-webkit-scrollbar-track {
  border-radius: 60px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 60px;
  height: 30px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}
