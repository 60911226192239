@import "../../theme/KidzCubicleTheme/Colors.scss";

.ForgotPassword__back-to-btn.MuiTypography-body1 {
  color: $black;
  cursor: pointer;
  text-transform: capitalize;
  margin-top: 1.5rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ForgotPassword--form-container.MuiGrid-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.ForgotPassword__form.MuiGrid-item {
  margin-top: 2rem;
}

.ForgotPassword__buttons.MuiGrid-item {
  margin-top: 2rem;
}

.ForgotPassword__description {
  color: $black;
  opacity: 0.6;
}
