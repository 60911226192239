.parent_Box {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 10px;
  justify-content: center;
  gap: 5px;
  // margin: 10px;
  cursor: pointer;
}

.avatar_container {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
}

.second_container {
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
}
