// Color names should be consistent with Colors.js
$primary: #f39200;
$appBackgroundColor: #ffffff;
$black: #4b3e3d;
$lightGrey: #b1b1b1;
$subTitleColor: #757575;
$inputColor: #909090;
$greyColor1: #4a4a4a;
$greyColor2: #eef6fb;
$lightBlue: #50a7da;
$whiteColor: #ffffff;
$redColor: #ff0000;
$themeBlack: #4b3e3d;
