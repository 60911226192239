.mainContainer {
  .customBoxShadow {
    border: 1.33px solid #d6d6d6;
    box-shadow: 0px 1.33px 5.33px 0px #00000029;
    border-radius: 12px;
    width: 100%;
    min-height: 363px;
    height: 100%;
    position: relative;
  }
  .btn {
    width: 100%;
    border-radius: 50px;
    padding: 10px 20px;
    text-transform: capitalize;
    box-shadow: none;
  }
  .popular {
    position: absolute;
    display: flex;
    justify-content: center;
    max-width: 105px;
    width: 100%;
    right: 20px;
    top: -10px;
    background-color: #f39200;
    padding: 3px 10px;
    color: white;
    border-radius: 100px;
    text-transform: uppercase;
    p {
      margin: 0;
      padding: 0;
      font-size: 10px;
    }
  }
}
