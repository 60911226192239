.main_box {
  padding-top: 20px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  // padding: "20px";
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
    flex-direction: column-reverse;
    padding: 0;
    width: 100%;
    gap: 0px;
  }
}

.order_info {
  display: none;
  @media (min-width: 1000px) {
    display: block;
    // max-width: 658px;
  }
}

.left_box {
  width: 45%;
  max-width: 658px;
  @media (max-width: 1000px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.right_box {
  width: 100%;
  max-width: 658px;
  @media (max-width: 1000px) {
    max-width: 100%;
    width: 100%;
    height: 150px;
    // background-color: #f39200;
    background-image: url('../../../public/mobile-frame.png');
    // padding: 20px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 16px;
  }
}

.underline_text {
  text-align: right;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: black;
  cursor: pointer;
  text-transform: capitalize;
  @media (max-width: 1000px) {
    color: white;
  }
}

.stripe-form {
  .card-element {
    padding: 14px 20px;
    margin-top: 5px;
    border-radius: 8px;
    border: 1px solid #dcdcdd;
    &:hover {
      border-color: #b0b0b0;
    }
    &:focus {
      border-color: #000;
    }
  }
}
