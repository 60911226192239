.CustomTabs__tabs {
  box-shadow: 0px 1px 4px 0px #00000029;
  border-radius: 360px;
  display: flex;
  align-items: center;
  max-width: fit-content;
  margin-bottom: 15px;
  width: auto;
  .MuiTabs-indicator {
    display: none;
  }

  .MuiTab-root {
    min-height: 38px;
    font-size: 16px;
    padding: 0 16px;
    @media (max-width: 881px) {
      min-width: 60px;
      font-size: 13px;
    }
  }

  .active {
    background: #f39200;
    color: #fff !important;
    border-radius: 25px;
  }
}
