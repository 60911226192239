@import "../../theme/KidzCubicleTheme/Colors.scss";

.ResetPassword__back-to-btn.MuiTypography-body1 {
  color: $black;
  cursor: pointer;
  text-transform: capitalize;
  margin-top: 1.5rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ResetPassword--form-container.MuiGrid-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.ResetPassword__form.MuiGrid-item {
  margin-top: 1rem;
}

.ResetPassword__buttons.MuiGrid-item {
  margin-top: 2rem;
}

.ResetPassword__description {
  color: $black;
  opacity: 0.6;
}
.ResetPassword__otp {
  display: flex;
  justify-content: center;
  margin: 0 10rem;
}
.ResetPassword__timer {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  color: $lightBlue;
}
